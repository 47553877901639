<template>
    <div class="action-gift-info">
        <div class="product-wrapper">
            <img :src="content.photo_url" @error="src = $blankProfile" />
            <div class="product-info" v-html="productInfo" />
        </div>
        <div class="body">
            <div class="sent-message">
                <div>{{ user.name ? user.name : user.nickname }} ({{ user.name ? user.nickname : '' }})님이</div>
                <div>감사의 선물을 보냈어요🎁</div>
            </div>
            <button v-if="!$store.getters.isMobile" class="btn" @click="onClickCTA">선물함으로 이동</button>
            <p v-if="$store.getters.isMobile" class="mobile-msg">받은 선물은 <strong>PC</strong>에서 확인해주세요!</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['message'],
    computed: {
        content() {
            if (!this.message) return {}
            return this.$mustParse(this.message.content)
        },
        user() {
            return this.content.user
        },
        productInfo() {
            return `<div class="description">${this.content.description}</div><div class="name">${this.content.name}</div>`
        },
    },
    methods: {
        onClickCTA() {
            this.$router.push({ name: 'GiftPage' })
        },
    },
}
</script>

<style>
.sent-message {
    margin-bottom: 12px;
    font-family: NotoSans-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #000000;
}
</style>
